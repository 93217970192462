<template>
  <div class="about">
    <!-- Page Title
	============================================= -->
    <section id="page-title" class="page-title-parallax include-header hero-section" style="
        padding: 250px 0;
        background-image: url('/assets/images/about/hero.jpg');
        background-size: cover;
        background-position: center center;" data-bottom-top="background-position:0px 400px;"
      data-top-bottom="background-position:0px -500px;">
      <div class="container clearfix">
        <h1 class="">Meet The eFlex Team</h1>
      </div>
    </section>
    <!-- #page-title end -->

    <!-- Content
	============================================= -->
    <section id="content">
      <div class="content-wrap main-mobile">
        <div class="container clearfix">
          <p>
            Welcome to eFlex, your trusted partner in building bespoke offshore
            teams. We specialize in providing comprehensive outsourcing
            solutions, handling everything from talent recruitment to team
            management, so you can focus on what matters most – growing your
            business.
          </p>

          <h3 class="font-weight-bold mt-5">Our Services</h3>
          <h4 class="mt-4">Building Tailored Offshore Teams</h4>

          <p>
            We understand that every business has unique needs. That's why we
            create custom offshore teams specifically designed to meet your
            requirements. Whether you need Software Developers, IT Help Desk
            professionals, Customer Support specialists, Marketers, Sales
            Specialists, Executive Assistants, Billing Specialists, Accountants,
            Team Leads, or more, we've got you covered.
          </p>

          <h4>Recruiting Top Talent</h4>
          <p>
            Meet some of the talented team members who help support our partners
            every day:
          </p>

          <h3 class="text-center mt-6">Software and Web Developers</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in developers" :key="index" />
          </div>
          <h3 class="text-center mt-5">IT Help Desk Professional</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in it" :key="index" />
          </div>
          <h3 class="text-center mt-5">Customer Service Representatives</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in csr" :key="index" />
          </div>
          <h3 class="text-center mt-5">Customer Support Specialist</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in css" :key="index" />
          </div>
          <h3 class="text-center mt-5">Marketer</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in marketer" :key="index" />
          </div>
          <h3 class="text-center mt-5">Quality Assurance</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in qa" :key="index" />
          </div>
          <h3 class="text-center mt-5">Sales Specialist</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in salesSpecialist" :key="index" />
          </div>
          <h3 class="text-center mt-5">Executive Assistant</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in executiveAssistant"
              :key="index" />
          </div>
          <h3 class="text-center mt-5">Billing Specialist</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in billingSpecialist" :key="index" />
          </div>
          <h3 class="text-center mt-5">Accountants</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in accountant" :key="index" />
          </div>
          <h3 class="text-center mt-5">Team Leads</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in teamLeads" :key="index" />
          </div>
          <h3 class="text-center mt-5">CAD Draftsman</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in Cad" :key="index" />
          </div>
          <h3 class="text-center mt-5">Logistics Specialist</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in logistics" :key="index" />
          </div>
          <h3 class="text-center mt-5">Executive Team Members</h3>
          <div class="d-flex flex-wrap justify-content-center" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in executiveTeamMembers"
              :key="index" />
          </div>
          <h3 class="text-center mt-5">Business Intelligence Analyst</h3>
          <div class="d-flex flex-wrap justify-content-center mb-6" :style="{
            gap: '10px',
          }">
            <Card :background="item.background" :bio="item.bio" :skills="item.skills" :name="item.name"
              :role="item.role" :experience="item.experience" v-for="(item, index) in Bia" :key="index" />
          </div>


          <Calendly />

          <!-- Client Section -->
          <div class="container clearfix">
            <div class="clear"></div>

            <div class="heading-block center">
              <h4>Our Clients</h4>
            </div>

            <ul class="clients-grid grid-2 grid-sm-4 grid-md-3 mb-0">
              <li class="grid-item">
                <img src="/assets/images/clients/aqqire.png" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/manscaped.jpeg" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/cvent.jpeg" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/iscribe.jpeg" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/copper.png" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/brightwheel.png" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/upcity.png" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/uservoice.png" alt="Clients" />
              </li>
              <li class="grid-item">
                <img src="/assets/images/clients/affinity.jpg" alt="Clients" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>

<style scoped>
#page-title {
  padding-top: 110px;
}

.callbutton {
  max-width: 400px;
  background-color: #1abc9c;
  border: 0;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  margin-top: 2em;
}

@media screen and (min-width: 769px) {
  .landing {
    margin-left: 23%;
  }
}

@media screen and (max-width: 480px) {
  .callbutton {
    max-width: 100%;
    display: block;
    padding: 10px 20px;
    font-size: 1rem;
    margin: 0 auto;
    margin-top: 2em;
    width: 100%;
  }

}

@media screen and (max-width: 1100px) {
  .hero-section {
    margin-bottom: -150px !important;
  }

  .main-mobile {
    padding-top: 16px;
  }
}
</style>


<script>
import Card from "../components/Card"
import Calendly from '../components/Calendly.vue'
export default {
  data() {
    return {
      testimonials: [
        "eFlex's team members are highly skilled and dedicated. They've become an invaluable extension of our business.",
        "The eFlex team has streamlined our operations and improved our customer service quality significantly.",
      ],
      developers: [
        {
          name: "Calixto Ong II",
          background: "/assets/images/TeamMembers/1.png",
          role: "Software / Sr Web Developer",
          skills: `PHP, MySQL, Postgres, Silverstripe, Laravel, Codeigniter, Python, NodeJS, Vue, HTML5, CSS3, and Javascript. Experienced in REST API architecture, DevOps, security, network administration, web/database management on Windows/Linux servers. Specialized in GSM/SMS gateways, AWS, dedicated servers, shared hosting, and domain name and SSL configuration.`,
          bio: `Calixto is our expert when it comes to DevOps, security, integration, cloud, and full-stack expert. He optimizes IT, boosts security, and delivers tech-business solutions.`,
          experience: "20 years",
        },
        {
          name: "Neil Infante",
          background: "/assets/images/TeamMembers/2.png",
          role: "Web Developer",
          skills: "Vue, Html, Css, Javascript",
          bio: "Neil builds and maintains websites and software applications.",
          experience: "2 Years",
        },
      ],
      it: [
        {
          name: "Anderson Sacare",
          background: "/assets/images/TeamMembers/3.png",
          role: "Sr. IT Specialist",
          skills: `IT troubleshooting, customer service, expertise in Cloud SSO (Okta), Google Workspace, AWS Management, MDM Management (Kandji and Intune), VPN, Zapier, advanced coding skills in JQL and Java Script. `,
          bio: `Andy is a seasoned IT pro with a strong tech background. Versatile in tech, team player, deadline-oriented and communicates complex solutions effectively.`,
          experience: "11 Years",
        },
        {
          name: "Stevenson Besorio",
          background: "/assets/images/TeamMembers/4.png",
          role: "Jr. IT Specialist",
          skills: `Kandji, Microsoft Intune admin center, Microsoft 365 admin center, Okta, JIRA Service Management, Notion, Crowdstrike, Beauceron. Vanta, Loom, 1Password, Whimsical, Island Management Console`,
          bio: `Steven is our Mac/Windows support pro. He excels in issue resolution, system management, user support, and problem-solving. `,
          experience: "5 Years",
        },
      ],
      csr: [
        {
          name: "Heather Mitz Sabling",
          background: "/assets/images/TeamMembers/5.png",
          role: "Customer Support Representative",
          skills: `Kustomer, Shopify`,
          bio: `Heather is an expert in providing excellent customer support and ensuring customer satisfaction.`,
          experience: "5 Years",
        },
        {
          name: "Renzo Castillo",
          background: "/assets/images/TeamMembers/6.png",
          role: "Customer Support Representative",
          skills: `Multilingual Support, Kustomer, Shopify`,
          bio: `Renzo provides assistance by solving customer inquiries via email, promoting products and services, and ensuring that the quality of service is improved by resolving complex customer inquiries while maintaining customer satisfaction.`,
          experience: "2 Years",
        },
      ],
      css: [
        {
          name: "Ronie Manuit",
          background: "/assets/images/TeamMembers/7.png",
          role: "Customer/Technical Support Specialist",
          skills: `Troubleshooting, Typeform, Ms Teams, Twilio Sendgrid, Zendesk, Notion, Salesforce, Postma`,
          bio: `Ronie is a passionate helper who loves to resolve inquiries, provide product info, ensure timely issue resolution, and prioritize customer satisfaction.`,
          experience: "6 Years",
        },
      ],
      marketer: [
        {
          name: "Denise Ayna Lebarbas",
          background: "/assets/images/TeamMembers/8.png",
          role: "Marketing Coordinator",
          skills: `Marketing Communication, PR, and events coordination, Gsuite, Canva, Monday.com, SAP, HubSpot, Photoshop Premiere, Facebook Business Suite, Mailchimp, Transportation Management System`,
          bio: `Denise is a skilled marketing professional who has successfully created and executed effective campaigns. Recognized for her creativity and flexibility, she thrives in changing environments and consistently achieves tangible outcomes. Committed to staying updated on industry trends, Denise is ready for ongoing success in the evolving marketing field.`,
          experience: "9 Years",
        },
      ],
      qa: [
        {
          name: "Kenneth Fontanilla",
          background: "/assets/images/TeamMembers/9.png",
          role: "Quality Assurance Specialist",
          skills: `Compliance and risk mitigation, Asana, Kustomer, ClickUp, Shopify, SharePoint, Trackstar Learn, Microsoft Teams, OneNote`,
          bio: `Ken is a dedicated professional with a keen eye for detail and a passion for maintaining quality benchmarks. He ensures the highest standards of product and service excellence, contributing to the success and reputation of the projects and teams he engages with.`,
          experience: "2 Years",
        },
        {
          name: "Janeth Dorado",
          background: "/assets/images/TeamMembers/10.png",
          role: "Senior Quality Assurance Engineer",
          skills: `MS Excel, MS SQL Server, Katalon Studio, Cypress, SahiPro, Postman, and Katalon Studio`,
          bio: `Janeth is a skilled professional specializing in various types of testing. She excels in managing the testing process and strategically dividing team tasks to ensure the timely fulfillment of product requirements. Her commitment to precision and efficiency ensures that products meet or exceed customer expectations, contributing to successful project outcomes.`,
          experience: "7 Years",
        },
      ],
      salesSpecialist: [
        {
          name: "Gene Rosal",
          background: "/assets/images/TeamMembers/11.png",
          role: "Sales Development Representative",
          skills: `Outplay, ZoomInfo, SalesNav, Salesforce, LinkedIn, ClickUp`,
          bio: `Gene is our resident sales, marketing, and lead generation expert. He helps us drive growth strategically. `,
          experience: "12 Years",
        },
        {
          name: "Ralph Cruz",
          background: "/assets/images/TeamMembers/12.png",
          role: "Account Executive",
          skills: `3CX, Hubspot, SalesNav, Asana, Monday.com, Oracle, Zapier, Salesforce, Active Campaign, Caspio App Builder, Ooma, Apollo.io, Dropbox, Canva, Hellosign, Quickbooks, Trello, IFTTT, Avaya, Hootsuite, power B.I., Zoho, Quickbase, Bubble.io, Visual Studio Code, Mailchimp, Siebel, Typeform, Wix, WordPress, Weebly, Shopify, Ecwid.`,
          bio: `Ralph helps us build lasting client relationships and exceeds targets through strategic communication and problem-solving.`,
          experience: "12 Years",
        },
        {
          name: "Eric Fernandez",
          background: "/assets/images/TeamMembers/13v2.png",
          role: "Sales Operation Manager",
          skills: `ZoomInfo, Salesforce, Outplay, SalesNav, Outreach, ClickUp, Stonly`,
          bio: `Eric develops strategies to empower the sales team with appropriate tools, resources, and training while extracting actionable insights from sales metrics to drive decision-making.`,
          experience: "12 Years",
        },
      ],
      executiveAssistant: [
        {
          name: "Nikki Fernandez",
          background: "/assets/images/TeamMembers/14.png",
          role: "Executive Assistant",
          skills: `C-Level Support, Email Management, Calendar Management, Detail-Oriented, Professional and discreet handling of confidential information, Click Up Automation, Asana, Canva`,
          bio: ` Nikki prioritizes confidentiality, demonstrates discretion in handling sensitive information, and efficiently utilizes office software/tools for improved administrative efficiency. `,
          experience: "6 Years",
        },
        {
          name: "Meigan Dannielle Lacno",
          background: "/assets/images/TeamMembers/15.png",
          role: "Executive Assistant",
          skills: `Magaya, 8x8, Cargowise, Canva, Notion`,
          bio: `Meigan brings a dynamic blend of Logistics and Customs specialty and virtual administrative support to match your needs in the business, particularly in the Logistics field.`,
          experience: "3 Years",
        },
      ],
      billingSpecialist: [
        {
          name: "Michael De Leon ",
          background: "/assets/images/TeamMembers/16.png",
          role: "Finance Associate ",
          skills: `Quickbooks, Oracle Netsuite, Expensify, Airbase, Bill.com, Stripe, Dropbox, Docusign`,
          bio: `Michael specializes in accurate account management and efficient financial reporting. He always ensures compliance with Financial Regulations.`,
          experience: "5 Years",
        },
        {
          name: "Vincent Zamora",
          background: "/assets/images/TeamMembers/17.png",
          role: "Billing Specialist",
          skills: `Customer billing support, Accounts Receivable, Salesforce, Monetize Now, Stripe`,
          bio: `Vincent manages invoicing tasks, monitors upcoming sales for timely invoicing, and adeptly reviews order forms. `,
          experience: "2 Years",
        },
      ],
      accountant: [
        {
          name: "Ronalyn Camino",
          background: "/assets/images/TeamMembers/18.png",
          role: "Senior Accountant",
          skills: `Onedrive, Excel, Applied Epic/GJ Journal Entry Import Utility, Outlook, Okta Verify, Suralink`,
          bio: `Rona is our resident expert in month-end/year-end financial reporting and bookkeeping. She excels in ensuring the precise processing of daily financial business operations for receivables and payables.`,
          experience: "21 Years",
        },
        {
          name: "John Carl Nicdao",
          background: "/assets/images/TeamMembers/19.png",
          role: "Accounts Payable Specialist",
          skills: ` Applied Epic, Accounting Expense Report Epic Bridge, J.P Morgan Access, NetSuite Accounting, Salesforce, DFIN Funsuite Arc Reporting, Quickbooks`,
          bio: `John Carl is an experienced AP (Accounts Payable) specialist recognized for his precise attention to detail and proficiency in enhancing financial processes.`,
          experience: "6 Years",
        },
      ],
      teamLeads: [
        {
          name: "Jharold Brylle Baruela",
          background: "/assets/images/TeamMembers/20.png",
          role: "Customer Support Team Leader",
          skills: `Kustomer, Shopify, Asana, ShipStation, Arca, ClickUp, Canva, ZenDesk, SalesForces, Atlassian Cloud Services, JIRA`,
          bio: `Jharold analyzes business models to enhance efficiency. He translates tech concepts into practical solutions and aligns initiatives with strategic objectives.`,
          experience: "7 Years",
        },
        {
          name: "Mark Alain Flores",
          background: "/assets/images/TeamMembers/21.png",
          role: "Sr. IT Support Specialist Team Leader",
          skills: `Okta, 1Password, Jira, MDM: Kandji and Intune, AV: Crowdstrike`,
          bio: `Mac is proficient in articulating organizational needs; he ensures that his team consistently delivers excellent customer service while assisting users.`,
          experience: "12 Years",
        },
      ],
      Cad: [
        {
          name: "Ragnar Pendon",
          background: "/assets/images/TeamMembers/22.png",
          role: "CAD Draftsman",
          skills: `AutoCAD, Photoshop, Sketchup, Blender, Maya`,
          bio: `Ragnar is an experienced architect with expertise that includes comprehensive proficiency in AutoCAD, adept construction supervision, nuanced project management, and limited property management experience.`,
          experience: "10 Years",
        },
      ],
      logistics: [
        {
          name: "Janiel Hyacinth Centeno",
          background: "/assets/images/TeamMembers/23.png",
          role: "Logistics Coordinator - Sales",
          skills: `Cargowise, SAP, Magaya, Borderwise`,
          bio: `Janiel ensures the seamless delivery of every shipment and consistently navigates complex customs processes to guarantee successful transportation. `,
          experience: "4 Years",
        },
        {
          name: "Eunice Anne Copon",
          background: "/assets/images/TeamMembers/24.png",
          role: "Logistics Coordinator - Sales and Operations",
          skills: `Asean Harmonized Tariff Nomenclature (AHTN),  Value Added Service Providers (Ekonek, Intercommerce & C-Dec), Harmonized Tariff Schedule for United States , CargoWise & Magaya, Brokers Website, Payment Platform (Sprint Pay & Paycargo), Trucking Platform (Forward Air & Speed Ship)`,
          bio: ` Eunice specializes in International Logistics/Freight Forwarding, where she helps ensure timely deliverables without compromising the quality of work.`,
          experience: "6 Years",
        },
      ],
      executiveTeamMembers: [
        {
          name: "Michelle Zamora",
          background: "/assets/images/TeamMembers/25.png",
          role: "Jr. Operations Manager / Project Manager",
          skills: `Leadership and Management, Planning and Organization, Data Analysis,  Communication and Collaboration, Click Up, Asana, Monday.com, Trello, Zebdesk, Kustomer, Hubspot, Shopify, Shipstation`,
          bio: `Michi is skilled in agile methodologies, budget management, and risk mitigation, with a successful history of delivering projects on time.`,
          experience: "4 Years",
        },
        {
          name: "Nathan Guerzon ",
          background: "/assets/images/TeamMembers/26.png",
          role: "Jr. Operations Manager / Project Manager",
          skills: `Strong verbal and written communication skills, problem-solving, strategic thinking, and analytical skills, MS Applications, Google Workspace, Click Up.`,
          bio: ` Nathan is a result-driven contributor passionate about optimizing processes and fostering team collaboration. `,
          experience: "8 Years",
        },
      ],
      Bia: [
        {
          name: "Rolan Reynacido",
          background: "/assets/images/TeamMembers/28.png",
          role: "Data and BI associate",
          skills: ` Data cleanup and visualization, MS Excel, Looker, Salesforce`,
          bio: `Rolan's role involves correctly formatting data to align with the accepted data types of the client database and extracting meaningful insights through analytics and visualizations.`,
          experience: "10 Years",
        },
        {
          name: "Jerald Rowell Cañete",
          background: "/assets/images/TeamMembers/27.png",
          role: "Data and BI associate",
          skills: `Data cleanup and visualization, MS Excel, Looker, Salesforce`,
          bio: `Jerald performs ETL and builds dashboards for clients.`,
          experience: "2 Years",
        },
      ],
    }
  },
  components: {
    Card,
    Calendly
  },
}
</script>
