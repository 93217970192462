<template>
  <!--- Flip on Y axis --->
  <div class="dm-flip-card">
    <div
      class="dm-front-card d-flex"
      :style="{
        backgroundImage: `url(${background})`,
        backgroundSize: `contain`,
        backgroundRepeat: 'no-repeat',
      }"></div>

    <div class="dm-back-card p-4 no-scrollbar border flex flex-column">
      <div class="overflow-auto scrollbar p-2 h-100 w-full">
        <Tabs>
          <Tab title="About">
            <div class="p-2">
              {{ bio }}
            </div>
          </Tab>
          <Tab title="Skills">
            <div
              class="p-2 text-capitalize gap-2 flex-grow-1 w-100">
              {{ skills }}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import Tabs from "./Tabs/Tabs.vue"
  import Tab from "./Tabs/Tab.vue"

  export default {
    props: {
      background: String,
      name: String,
      role: String,
      skills: String,
      bio: String,
      experience: String,
    },
    data() {
      return {}
    },
    components: {
      Tab,
      Tabs,
    },
  }
</script>

<style scoped>
  .dm-flip-card {
    overflow: hidden;
    /* aspect-ratio: 1.1; */
    position: relative;
    width: 300px;
    height: 250px;
    background: red;
    background: transparent;
    transform-style: preserve-3d;
    border-radius: 5px;
  }

  .dm-front-card {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }

  .dm-front-card:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
  }

  .dm-back-card {
    overflow-y: auto;
    border: 0px solid rgb(90, 89, 89);
    border-radius: 5px;
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg); /*-180deg to flip direction*/
    transition: transform 1s;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .dm-flip-card:hover .dm-front-card {
    transform: rotateY(-180deg); /*180deg to flip direction*/
  }

  .dm-flip-card:hover .dm-back-card {
    transform: rotateY(0deg);
  }

  .no-scrollbar,
  .no-scrollbar::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .scrollbar::-webkit-scrollbar {
    width: 2px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #888;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scrollbar::-webkit-scrollbar-button {
    display: none;
  }
</style>
