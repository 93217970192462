var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_vm._m(0),_c('section',{attrs:{"id":"content"}},[_c('div',{staticClass:"content-wrap main-mobile"},[_c('div',{staticClass:"container clearfix"},[_c('p',[_vm._v(" Welcome to eFlex, your trusted partner in building bespoke offshore teams. We specialize in providing comprehensive outsourcing solutions, handling everything from talent recruitment to team management, so you can focus on what matters most – growing your business. ")]),_c('h3',{staticClass:"font-weight-bold mt-5"},[_vm._v("Our Services")]),_c('h4',{staticClass:"mt-4"},[_vm._v("Building Tailored Offshore Teams")]),_c('p',[_vm._v(" We understand that every business has unique needs. That's why we create custom offshore teams specifically designed to meet your requirements. Whether you need Software Developers, IT Help Desk professionals, Customer Support specialists, Marketers, Sales Specialists, Executive Assistants, Billing Specialists, Accountants, Team Leads, or more, we've got you covered. ")]),_c('h4',[_vm._v("Recruiting Top Talent")]),_c('p',[_vm._v(" Meet some of the talented team members who help support our partners every day: ")]),_c('h3',{staticClass:"text-center mt-6"},[_vm._v("Software and Web Developers")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.developers),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("IT Help Desk Professional")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.it),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Customer Service Representatives")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.csr),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Customer Support Specialist")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.css),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Marketer")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.marketer),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Quality Assurance")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.qa),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Sales Specialist")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.salesSpecialist),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Executive Assistant")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.executiveAssistant),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Billing Specialist")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.billingSpecialist),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Accountants")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.accountant),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Team Leads")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.teamLeads),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("CAD Draftsman")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.Cad),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Logistics Specialist")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.logistics),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Executive Team Members")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center",style:({
            gap: '10px',
          })},_vm._l((_vm.executiveTeamMembers),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('h3',{staticClass:"text-center mt-5"},[_vm._v("Business Intelligence Analyst")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center mb-6",style:({
            gap: '10px',
          })},_vm._l((_vm.Bia),function(item,index){return _c('Card',{key:index,attrs:{"background":item.background,"bio":item.bio,"skills":item.skills,"name":item.name,"role":item.role,"experience":item.experience}})}),1),_c('Calendly'),_vm._m(1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-title-parallax include-header hero-section",staticStyle:{"padding":"250px 0","background-image":"url('/assets/images/about/hero.jpg')","background-size":"cover","background-position":"center center"},attrs:{"id":"page-title","data-bottom-top":"background-position:0px 400px;","data-top-bottom":"background-position:0px -500px;"}},[_c('div',{staticClass:"container clearfix"},[_c('h1',{},[_vm._v("Meet The eFlex Team")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container clearfix"},[_c('div',{staticClass:"clear"}),_c('div',{staticClass:"heading-block center"},[_c('h4',[_vm._v("Our Clients")])]),_c('ul',{staticClass:"clients-grid grid-2 grid-sm-4 grid-md-3 mb-0"},[_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/aqqire.png","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/manscaped.jpeg","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/cvent.jpeg","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/iscribe.jpeg","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/copper.png","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/brightwheel.png","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/upcity.png","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/uservoice.png","alt":"Clients"}})]),_c('li',{staticClass:"grid-item"},[_c('img',{attrs:{"src":"/assets/images/clients/affinity.jpg","alt":"Clients"}})])])])
}]

export { render, staticRenderFns }