<template>
  <div>
    <ul class="d-flex justify-content-between list-unstyled pointer mb-2" >
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        class='px-2 py-1'
        :class="{ 'font-weight-bold': index == selectedIndex }"
        :style="{
          background: index === selectedIndex ? '#e6e1e1' : 'transparent',
          borderRadius: '10px'
        }">
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedIndex: 0,
        tabs: [],
      }
    },
    created() {
      this.tabs = this.$children
    },
    mounted() {
      this.selectTab(0)
    },
    methods: {
      selectTab(i) {
        this.selectedIndex = i

        // loop over all the tabs
        this.tabs.forEach((tab, index) => {
          tab.isActive = index === i
        })
      },
    },
  }
</script>

<style>
  .pointer {
    cursor: pointer;
  }
</style>
